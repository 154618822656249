import { ITopic } from "./../../@types/generated/contentful.d";
import { ClientSide } from "~/@types";
import { ALGOLIA_INDICES } from "~/config";
import { getAllEntriesOfType } from "~/contentful/index.server";
import { algoliaClient } from "./../../components/search/AlgoliaProvider";
import { IComponentDataTopic } from "../ComponentDataProvider";

export async function getTopicsWithTotals(
  locale: string
): Promise<IComponentDataTopic[]> {
  const topicTotals = (await algoliaClient
    .search([
      {
        indexName: ALGOLIA_INDICES.all,
        facet: "topic.title",
        type: "facet",
      },
    ])
    .then(({ results }) => {
      return results[0];
    })
    .catch((err) => {
      console.error(err);
    })) as unknown as {
    facetHits: {
      value: string;
      count: number;
    }[];
  };

  const contentfulTopics = (await getAllEntriesOfType({
    content_type: "topic",
    locale,
    otherParams: {
      "fields.hidden[in]": "no",
    },
  })) as ClientSide<ITopic>[];

  return contentfulTopics.map((t) => ({
    ...t,
    total:
      topicTotals?.facetHits?.find((fh) => fh.value === t.fields.title)
        ?.count ?? 0,
  }));
}
