import { Menu } from "@headlessui/react";
import { useConfigure, useMenu } from "react-instantsearch-hooks-web";
import type { MenuConnectorParams } from "instantsearch.js/es/connectors/menu/connectMenu";
import type { UiState } from "instantsearch.js/es/types";
import { ALGOLIA_FACETS, ALGOLIA_INDICES } from "~/config";
import { RoundedCta, TextCta } from "../ctas";
import { Arrow, Carrot } from "../icons";
import AlgoliaProvider from "./AlgoliaProvider";
import { useReducer } from "react";
import qs from "qs";
import clsx from "clsx";
import rangeRight from "lodash/rangeRight";
import { useMedia } from "react-use";

function getYears() {
  return rangeRight(1980, new Date().getFullYear() + 1).map(String);
}

type SentenceSearchProps = {
  onFreeSearchClick: () => void;
  onClose: () => void;
};

type MenuState = {
  topic: string | null;
  region: string | null;
  year: string | null;
};

const menuReducer = (state: MenuState, nextState: Partial<MenuState>) => ({
  ...state,
  ...nextState,
});

export function SentenceSearch({
  onFreeSearchClick,
  onClose,
}: SentenceSearchProps) {
  const isLg = useMedia("(min-width: 1024px)", false);
  const [menuState, setMenuState] = useReducer(menuReducer, {
    topic: null,
    region: null,
    year: null,
  } as MenuState);

  useConfigure({
    // Get facets but no results
    hitsPerPage: 0,
  });

  const nextAlgoliaState: UiState = {
    [ALGOLIA_INDICES.all]: {
      refinementList: {
        ...(menuState.topic && {
          [ALGOLIA_FACETS.insights.topic]: [menuState.topic],
        }),
        ...(menuState.region && {
          [ALGOLIA_FACETS.insights.region]: [menuState.region],
        }),
        ...(menuState.year && {
          [ALGOLIA_FACETS.insights.year]: [menuState.year],
        }),
      },
    },
  };

  const anySelected = Object.values(menuState).some(Boolean);

  return (
    <div className="flex h-[calc(100vh-80px)] flex-col justify-between overflow-y-scroll lg:block">
      <div
        className={clsx(
          "global-container z-10 grid w-screen grid-cols-1 grid-rows-[repeat(6,60px)] items-baseline justify-center gap-y-0 gap-x-5 text-white",
          "lg:fixed lg:top-1/2 lg:max-w-[100vw] lg:-translate-y-1/2 lg:grid-cols-[minmax(200px,_auto)_auto] lg:grid-rows-3 lg:gap-y-7 lg:pt-0"
        )}
      >
        <FacetMenu
          label="I'm interested in"
          nullValue="all topics"
          onClick={(topic) => setMenuState({ topic })}
          selected={menuState.topic}
          facetParams={{
            attribute: ALGOLIA_FACETS.insights.topic,
            sortBy: ["name:asc"],
          }}
        />
        <FacetMenu
          label={isLg ? "from the region" : "from"}
          nullValue="all regions"
          onClick={(region) => setMenuState({ region })}
          selected={menuState.region}
          facetParams={{
            attribute: ALGOLIA_FACETS.insights.region,
            sortBy: ["name:asc"],
          }}
        />
        <FacetMenu
          label={isLg ? "specific to year" : "specific to"}
          nullValue="all years"
          facetParams={{
            attribute: ALGOLIA_FACETS.insights.year,
            sortBy: ["name:desc"],
          }}
          onClick={(year) => setMenuState({ year })}
          selected={menuState.year}
          customOptions={getYears()}
        />
        <RoundedCta
          navLinkProps={{
            reloadDocument: true,
          }}
          id="sentence-search-cta"
          disabled={!anySelected}
          onClick={onClose}
          to={{
            pathname: "/search",
            search: qs.stringify(nextAlgoliaState),
          }}
          className="col-span-full mt-20 justify-self-center lg:mt-16"
          label="Apply"
          kind="white"
          icon={<Arrow />}
          dataAttrs={{
            "data-topic": menuState.topic,
            "data-region": menuState.region,
            "data-year": menuState.year,
          }}
        />
      </div>
      <div className="global-container my-20 w-full lg:fixed lg:bottom-36 lg:m-0 lg:max-w-[100vw]">
        <TextCta
          id="free-search-button"
          kind="white"
          label="Go Back to Search"
          className="mx-auto"
          icon={<Arrow />}
          type="button"
          onClick={onFreeSearchClick}
        />
      </div>
    </div>
  );
}

type FacetMenuProps = {
  label: string;
  nullValue: string;
  onClick: (option: string | null) => void;
  selected: string | null;
  facetParams: MenuConnectorParams;
  customOptions?: string[];
};

function FacetMenu({
  onClick,
  label,
  nullValue,
  selected,
  facetParams,
  customOptions,
}: FacetMenuProps) {
  const { items } = useMenu({ limit: 100, ...facetParams });
  const options = customOptions ?? items.map((item) => item.value);
  return (
    <Menu>
      {({ open }) => (
        <>
          <div className="h4 mr-1 self-end whitespace-nowrap text-secondary_text lg:self-auto">
            {label}
          </div>
          <div className="h4 relative min-w-full self-start lg:min-w-[20rem] lg:self-auto">
            <Menu.Button className="flex w-full items-center justify-between border-b-[1px] border-solid border-white py-1 text-left">
              <span className="h4 px-1">{selected ?? nullValue}</span>
              <Carrot
                className={clsx("transition-transform", { "rotate-180": open })}
              />
            </Menu.Button>
            <Menu.Items
              as="ul"
              className="absolute z-30 max-h-[40vh] w-full overflow-x-clip overflow-y-scroll bg-onyx pt-2"
            >
              {selected && (
                <Menu.Item
                  as="li"
                  className="cursor-pointer py-3 px-4 hover:bg-charcoal"
                  onClick={() => onClick(null)}
                >
                  {nullValue}
                </Menu.Item>
              )}
              {options.map((option) => (
                <Menu.Item
                  as="li"
                  className="cursor-pointer py-3 px-4 hover:bg-charcoal"
                  onClick={() => onClick(option)}
                  key={option}
                >
                  {option}
                </Menu.Item>
              ))}
            </Menu.Items>
          </div>
        </>
      )}
    </Menu>
  );
}

export default function SentenceSearchWrapper(props: SentenceSearchProps) {
  return (
    <AlgoliaProvider indexName={ALGOLIA_INDICES.all}>
      <SentenceSearch {...props} />
    </AlgoliaProvider>
  );
}
