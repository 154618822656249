import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document as ContentfulDocument } from "@contentful/rich-text-types";
import { useMemo } from "react";
import { AlgoliaInsight, ClientSide } from "~/@types";
import { IInsight } from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { useRelatedInsights } from "~/contexts/dataUtils/getRelatedInsights";
import { algoliaInsightGetters, contentfulInsightGetters } from "~/utils";
import ColumnCard from "../Cards/ColumnCards";
import FootnotesList from "./FootnotesList";
import InsightNav from "./InsightNav";
import Standfirst from "./Standfirst";
import Tags from "./Tags";

type InsightComponentProps = {
  insight: ClientSide<IInsight>;
  url: string;
};

export default function InsightComponent({
  insight,
  url,
}: InsightComponentProps) {
  // Memoize tag slugs to avoid recomputations
  const tagSlugs = useMemo(
    () =>
      insight.fields.tags?.map((tag) => tag.fields.slug).filter(Boolean) || [],
    [insight.fields.tags]
  );

  const currentObjectID = insight.sys.id;
  const relatedInsights = useRelatedInsights(tagSlugs, currentObjectID);
  const shouldShowRelatedArticles =
    insight.fields.type !== "Tribute" &&
    (relatedInsights.data || insight.fields.relatedInsights);

  return (
    <article
      itemScope
      itemType="https://schema.org/Article"
      id={url}
      className={`relative overflow-x-clip ${
        insight.fields.embargoed ? "select-none" : ""
      }`}
      data-content-type="insight"
      data-module-title={insight.fields.slug}
    >
      <InsightNav insight={insight} />
      {insight.fields.body && (
        <div className="tbi-grid pb-10" itemProp="articleBody">
          {insight.fields.standfirst && (
            <Standfirst {...insight.fields.standfirst.fields} />
          )}
          {documentToReactComponents(
            insight.fields.body as ContentfulDocument,
            getComponentFromContentfulRichTextEntry
          )}
        </div>
      )}
      <FootnotesList />
      {shouldShowRelatedArticles && (
        <div className="related-insights">
          <h4 className="h4 mx-auto max-w-content-max px-mobile-gutter lg:px-desktop-gutter">
            Related Articles
          </h4>
          {insight.fields.relatedInsights ? (
            // Display related articles from Contentful
            <ColumnCard
              insights={insight.fields.relatedInsights?.map((entry) =>
                contentfulInsightGetters(entry as ClientSide<IInsight>)
              )}
            />
          ) : // Display related articles fetched from Algolia
          relatedInsights.loading || relatedInsights.error ? (
            <p className="mx-auto max-w-content-max px-mobile-gutter lg:px-desktop-gutter">
              Loading articles...
            </p>
          ) : (
            <ColumnCard
              insights={
                relatedInsights.data?.map((insight) =>
                  algoliaInsightGetters(insight as unknown as AlgoliaInsight)
                ) ?? []
              }
            />
          )}
        </div>
      )}
      <Tags tags={insight.fields.tags?.map((t) => t?.fields).filter(Boolean)} />
    </article>
  );
}
