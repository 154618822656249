import clsx from "clsx";
import { useEffect, useState } from "react";
import {
  IWistiaPlayerFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { adjustValueBasedOnScreenSize } from "../utils/getValuesBasedOnScreenSize";
import useIsMobile from "../utils/useIsMobile";

declare global {
  interface Window {
    _wq: any[];
  }
}

type WistiaPlayerProps = {
  fields: SpecificLocaleFields<IWistiaPlayerFields>;
};

function WistiaPlayer({ fields }: WistiaPlayerProps) {
  const videoData = fields.videoPlayer.items[0];
  const hashedId = videoData.hashed_id;
  const timestamp = `${String(Math.floor(videoData.duration / 60)).padStart(
    2,
    "0"
  )}:${String(Math.floor(videoData.duration % 60)).padStart(2, "0")}`;
  const isMobile = useIsMobile();
  const isTablet = useIsMobile(1024);
  const [playBarHeight, setPlaybarHeight] = useState(0);
  const timeStampSpacing = isMobile ? 8 : 20;

  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = `https://fast.wistia.com/embed/medias/${hashedId}.jsonp`;
    script1.async = true;

    const script2 = document.createElement("script");
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script2.async = true;

    const handleWistiaReady = () => {
      window._wq = window._wq || [];
      window._wq.push({
        id: hashedId,
        options: {
          controlsVisibleOnLoad: false,
          playButton: false,
          seo: true,
        },
        onReady: function (video: any) {
          const playOverlay: HTMLElement | null = document.querySelector(
            `#play-overlay-${hashedId}`
          );
          // Release control visibility when video plays
          video.bind("play", () => {
            video.releaseControls("keep-controls-visible");
            const playBarH =
              document.querySelector(".w-bottom-bar")?.clientHeight ?? 0;
            setPlaybarHeight(playBarH);
            if (playOverlay) playOverlay.style.display = "none";
          });

          // Request controls to stay visible on pause
          video.bind("pause", () => {
            video.requestControls("keep-controls-visible");
            const playBarH =
              document.querySelector(".w-bottom-bar")?.clientHeight ?? 0;
            if (playBarH !== 0) {
              setPlaybarHeight(playBarH);
            }

            if (playOverlay) playOverlay.style.display = "flex";
          });

          // Custom overlay click to play the video
          if (playOverlay) {
            playOverlay.addEventListener("click", () => video.play());
          }
        },
      });
    };

    // Append scripts to the DOM
    const container = document.getElementById(`wrapper-${hashedId}`);
    container?.appendChild(script1);
    container?.appendChild(script2);

    // Load Wistia after scripts have been appended
    handleWistiaReady();

    return () => {
      if (container) {
        container.innerHTML = "";
      }
    };
  }, [hashedId]);

  return (
    <section
      id={`wrapper-${hashedId}`}
      data-content-type="wistiaPlayer"
      data-module-title={fields.moduleTitle}
      className={clsx("relative col-span-full py-20", {
        "bg-black": fields.bottomColor === "black",
        "bg-white": fields.bottomColor === "white",
        "bg-slate": fields.bottomColor === "slate",
      })}
      style={{ backgroundColor: fields.colorPalette?.fields.accentColor }}
    >
      <div
        className={clsx("absolute top-0 h-1/2 w-full", {
          "bg-black": fields.topColor === "black",
          "bg-white": fields.topColor === "white",
          "bg-slate": fields.topColor === "slate",
        })}
        style={{ backgroundColor: fields.colorPalette?.fields.backgroundColor }}
      />
      <div className="tbi-grid">
        <div
          className="relative col-span-full"
          style={{
            padding: `${
              fields.spacingAroundVideo &&
              adjustValueBasedOnScreenSize(
                fields.spacingAroundVideo,
                isMobile,
                isTablet
              )
            }px`,
          }}
        >
          {fields.graphicElements &&
            fields.graphicElements.map((svg, index) => {
              const x = svg.fields.x || 0;
              const y = svg.fields.y || 0;
              const svgWidth =
                svg.fields.graphicElement?.fields.file.details.image?.width;
              const adjustedWidth =
                svgWidth &&
                adjustValueBasedOnScreenSize(svgWidth, isMobile, isTablet);

              return (
                <img
                  key={index}
                  src={svg.fields.graphicElement?.fields.file.url}
                  alt={svg.fields.graphicElement?.fields.title}
                  style={{
                    position: "absolute",
                    left: `${x}%`,
                    top: `${y}%`,
                    transform: `translate(-${x}%, -${y}%)`,
                    zIndex: svg.fields.z === 1 ? 1 : 0,
                    width: adjustedWidth,
                  }}
                />
              );
            })}
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                position: "absolute",
                width: "100%",
                top: "0",
                left: "0",
              }}
            >
              <div
                className={`wistia_embed wistia_async_${hashedId}`}
                style={{ height: "100%", width: "100%" }}
              ></div>
            </div>
          </div>
          <div
            id={`play-overlay-${hashedId}`}
            className="absolute top-0 left-0 flex h-full w-full cursor-pointer items-center justify-center"
            style={{ display: "flex" }}
          >
            {/* Custom Play Button */}
            <button
              id={`custom-play-button-${hashedId}`}
              className={clsx(
                "z-[1] flex h-11 w-11 items-center justify-center rounded-full bg-white text-black md:h-16 md:w-16 lg:h-24 lg:w-24"
              )}
              style={{ marginTop: `-${playBarHeight}px` }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="12"
                viewBox="0 0 10 12"
                fill="none"
                className="lg:hidden"
                role="img"
              >
                <title>play button</title>
                <path
                  d="M0.863281 1.20195V10.8019C0.863281 11.2219 1.33329 11.4719 1.68329 11.2319L8.90332 6.34195C9.21332 6.13195 9.21332 5.67195 8.90332 5.47195L1.68329 0.761946C1.33329 0.531946 0.873291 0.781947 0.873291 1.20195H0.863281Z"
                  fill="black"
                />
              </svg>

              <span className="body-large hidden lg:block">PLAY</span>
            </button>
            <span
              className="body-small absolute z-[1] rounded-md bg-black/60 px-2 py-1 text-white"
              style={{
                right: fields.spacingAroundVideo
                  ? `${
                      adjustValueBasedOnScreenSize(
                        fields.spacingAroundVideo,
                        isMobile,
                        isTablet
                      ) + timeStampSpacing
                    }px`
                  : `${timeStampSpacing}px`,
                bottom: fields.spacingAroundVideo
                  ? `${
                      adjustValueBasedOnScreenSize(
                        fields.spacingAroundVideo,
                        isMobile,
                        isTablet
                      ) +
                      playBarHeight +
                      timeStampSpacing
                    }px`
                  : playBarHeight + timeStampSpacing,
              }}
            >
              {timestamp}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WistiaPlayer;
