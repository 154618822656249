import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { getFrontifyImageURL } from "./getFrontifyImage";

//transformations = "?twic=v1/resize=/background="
export const getTwicPicsURL = (
  image: ClientSide<IImageFields>,
  transformations: string = ""
) => {
  const TWICPICS_DOMAIN = "https://tbi-assets.twic.pics";
  const ffySplitter = "frontify-enterprise-files-eu";
  const ctfSplitter = "75ila1cntaeh";

  const imageUrl = image?.frontifyImage
    ? getFrontifyImageURL(image)
    : image?.image?.fields?.file?.url;

  if (imageUrl?.endsWith(".svg")) {
    return image?.frontifyImage
      ? `${TWICPICS_DOMAIN}/ffy${imageUrl?.split(ffySplitter)[1]}`
      : `${TWICPICS_DOMAIN}/ctf${imageUrl?.split(ctfSplitter)[1]}`;
  }

  const url = image?.frontifyImage
    ? `${TWICPICS_DOMAIN}/ffy${
        imageUrl?.split(ffySplitter)[1]
      }?fm=webp${transformations}`
    : `${TWICPICS_DOMAIN}/ctf${
        imageUrl?.split(ctfSplitter)[1]
      }?fm=webp${transformations}`;

  return url;
};

export const getTwicPicsURLAsset = (url: string) => {
  const TWICPICS_DOMAIN = "https://tbi-assets.twic.pics";
  const ctfSplitter = "75ila1cntaeh";

  return `${TWICPICS_DOMAIN}/ctf${url.split(ctfSplitter)[1]}?fm=webp`;
};

export const getTwicPicsURLFromSrc = (
  url: string,
  transformations: string = ""
) => {
  const TWICPICS_DOMAIN = "https://tbi-assets.twic.pics";
  const ctfSplitter = "75ila1cntaeh";

  return `${TWICPICS_DOMAIN}/ctf${url.split(
    ctfSplitter
  )}?fm=webp${transformations}`;
};
