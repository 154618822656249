import { AlgoliaInsight } from "~/@types";
import { LogoWhiteOnBlack } from "../icons";
import { ContentfulCenteredImage } from "../shared";
import NavOrExternalLink from "../shared/NavOrExternalLink";
import { getPrettyDate } from "../utils/getPrettyDate";

type SearchCardProps = {
  insight: AlgoliaInsight;
  onClick?: () => void;
};

export default function InsightSearchCard({
  insight,
  onClick,
}: SearchCardProps) {
  const insightLocation = `/insights/${insight.topic?.slug}/${insight.slug}`;
  const topicLocation = `/insights/${insight.topic?.slug}`;

  return (
    <div
      data-content-type="insightsSearchCard"
      data-module-title={insight.slug}
      className="global-grid items-top w-full border-t-[1px] border-none border-black pb-10 last:border-b-[1px] lg:border-solid lg:py-10"
    >
      <div className="lg:global-grid relative col-span-full flex lg:items-center">
        {insight.image ? (
          <ContentfulCenteredImage
            className="h-[119px] w-[90px] flex-shrink-0 lg:col-span-2 lg:aspect-[16_/_9] lg:h-auto lg:max-h-[119px] lg:w-full"
            image={insight.image}
          />
        ) : (
          <div
            className={
              "flex aspect-[9/16] max-h-[119px] w-[90px] flex-shrink-0 items-center justify-center bg-black object-cover object-center duration-1000 lg:col-span-2 lg:aspect-[16_/_9] lg:w-full lg:group-hover:scale-105"
            }
          >
            <LogoWhiteOnBlack className="sm:h-[50px] sm:w-[50px]" />
          </div>
        )}
        <div className="col-[2_/_-1] ml-4 lg:col-[4_/_10] lg:ml-0">
          <NavOrExternalLink
            to={topicLocation}
            className="relative z-[1] col-[2_/_-1] mb-3 flex items-center lg:col-[4_/_-1] lg:mb-4"
          >
            <div
              className="mr-2 aspect-square h-[10px] rounded-full lg:mr-3 lg:h-3"
              style={{
                backgroundColor: insight.topic?.color,
              }}
            />
            <p className="eyebrows">{insight.topic?.title}</p>
          </NavOrExternalLink>
          <NavOrExternalLink
            onClick={onClick}
            to={insightLocation}
            className="before:absolute before:inset-0 before:content-['']"
          >
            <h3 className="h5 lg:h3 mb-4">{insight.title}</h3>
            <p className="meta text-tertiary_text">
              {getPrettyDate(insight.date)}
            </p>
          </NavOrExternalLink>
        </div>
      </div>
    </div>
  );
}
