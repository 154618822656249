import { Popover } from "@headlessui/react";
import clsx from "clsx";
import { CloseCta } from "../ctas";
import { Search } from "../icons";
import FreeSearch from "./FreeSearch";
import SentenceSearch from "./SentenceSearch";
import { MouseEventHandler, useState } from "react";
import MobileHomeIcon from "../nav/assets/MobileHomeIcon";
import { PreventBodyScroll } from "../utils/PreventBodyScroll";
import NavOrExternalLink from "../shared/NavOrExternalLink";

type SearchTakeoverProps = {
  onMouseEnter?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
};

type ViewName = "free-search" | "sentence-search";

export default function SearchTakeover({
  onMouseEnter,
  onMouseLeave,
}: SearchTakeoverProps) {
  const [view, setView] = useState<ViewName>("free-search");

  return (
    <Popover className="flex-center">
      {({ close, open }) => (
        <>
          <PreventBodyScroll enabled={open} />
          <Popover.Button
            id="search-open-button"
            className="hover:opacity-60"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            tabIndex={0}
          >
            <Search className="ml-10 h-6 w-6" aria-label="search" />
          </Popover.Button>
          <Popover.Overlay
            className={clsx("fixed inset-0 bg-black opacity-90", {
              hidden: view === "sentence-search",
            })}
          />
          <Popover.Panel
            className={clsx("fixed top-0 left-0 right-0 z-50 w-screen", {
              "h-screen bg-black": view === "sentence-search",
            })}
            unmount
          >
            <div className="w-full bg-black">
              <div className="h-nav global-container flex w-full items-center justify-between px-20 text-white">
                <h1 className={clsx("aeonik text-14 uppercase text-white")}>
                  Tony Blair Institute for Global Change
                </h1>
                <Popover.Button id="search-close-button">
                  <CloseCta kind="white" as="span" />
                </Popover.Button>
              </div>
            </div>
            <div
              className={clsx(
                "global-container flex h-mobile-nav items-center justify-between bg-black sm:hidden",
                { hidden: view === "free-search" }
              )}
            >
              <NavOrExternalLink to="/" className=" text-white">
                <MobileHomeIcon />
              </NavOrExternalLink>
              <div className="flex w-max justify-between">
                <Popover.Button
                  id="search-close-button"
                  className="outline-none"
                >
                  <CloseCta as="span" kind="white" />
                </Popover.Button>
              </div>
            </div>
            {view === "free-search" && (
              <FreeSearch
                onSentenceSearchClick={() => setView("sentence-search")}
                onClose={close}
              />
            )}
            {view === "sentence-search" && (
              <SentenceSearch
                onFreeSearchClick={() => setView("free-search")}
                onClose={close}
              />
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
