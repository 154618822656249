import { TwicImg } from "@twicpics/components/react";
import {
  IImageFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getTwicPicsURL } from "../utils/getTwicPicsURL";
import { useState } from "react";

type ImgProps = {
  image: SpecificLocaleFields<IImageFields>;
  className?: string;
};
type TwicState = "new" | "loading" | "done" | "error";

export default function ContentfulImgTwipics({
  image,
  className = "",
}: ImgProps) {
  const [state, setState] = useState<TwicState | undefined>(undefined);

  const handleStateChange = (stateEvent: { state: TwicState }) => {
    const { state } = stateEvent;
    setState(state);
  };
  if (state === "error") {
    return (
      <img
        className={className}
        src="https://tbi-assets.s3.eu-west-2.amazonaws.com/all-images/placeholder.png"
        alt="placeholder"
      />
    );
  }

  return (
    <TwicImg
      src={getTwicPicsURL(image)}
      alt={image.alt ? image.alt : "image"}
      aria-label={image.alt ? image.alt : "image"}
      onStateChange={handleStateChange}
      placeholder="maincolor"
    />
  );
}
