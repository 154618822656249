import { PropsWithChildren, useEffect, useRef } from "react";
import { InstantSearch, useInstantSearch } from "react-instantsearch-hooks-web";
import { history } from "instantsearch.js/es/lib/routers/index.js";
import { createInsightsMiddleware } from "instantsearch.js/es/middlewares/index.js";
import algoliasearch, { SearchClient } from "algoliasearch/lite";
import { UiState } from "instantsearch.js/es/types";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_FACETS,
  ALGOLIA_INDICES,
  ALGOLIA_SEARCH_KEY,
} from "~/config";
import omit from "lodash/omit";
import pick from "lodash/pick";
import { useEffectOnce } from "react-use";
import qs from "qs";
import { off, on } from "../utils/misc";
import AlgoliaInsightsClient from "search-insights";
import { useCookieContext } from "../utils/useCookies";

const allAlgoliaIndices = Object.values(ALGOLIA_INDICES) as string[];

export const algoliaClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

type AlgoliaProviderProps = PropsWithChildren<{
  indexName: string;
  url?: string;
  disableSearchOnEmpty?: boolean;
  /**
   * If provided, removes the topic facet from the url
   * (but keeps the facet in the state/request params)
   */
  currentTopicName?: string;
  // Used for algolia analytics to track searches.
  // Prevents Algolia from using the IP address of the server.
  xForwardedFor?: string;
}>;

export default function AlgoliaProvider({
  children,
  url,
  indexName,
  disableSearchOnEmpty,
  currentTopicName,
  xForwardedFor,
}: AlgoliaProviderProps) {
  const searchClient = useRef<SearchClient>({
    ...algoliaClient,
    // Prevent searches on empty queries
    // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/react/#detecting-empty-search-requests
    search(requests) {
      if (
        disableSearchOnEmpty &&
        requests.every(({ params }) => !params?.query)
      ) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            hitsPerPage: 0,
            exhaustiveNbHits: false,
            query: "",
            params: "",
          })),
        });
      }

      let headers: Record<string, string> = {};
      if (typeof window === "undefined" && xForwardedFor) {
        headers["X-Forwarded-For"] = xForwardedFor;
      }

      requests = requests.map((req) => {
        return {
          ...req,
          params: {
            ...(req.params ?? {}),
            // This doesn't actually do any tracking. It just ensures that we have an ID
            // for the last search if we decide to track a click later.
            // https://www.algolia.com/doc/api-reference/api-parameters/clickAnalytics/#usage-notes
            clickAnalytics: true,
          },
        };
      });

      return algoliaClient.search(requests, { headers });
    },
  }).current;
  return (
    /**
     * Routing is optional because we don't need search params for the
     * search takeover, only the results page.
     */
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      routing={
        url
          ? {
              // Try this to clean up the URLs
              // https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/react-hooks/#rewriting-urls-manually
              router: history({
                // Without this, instantSearch overrides unrelated search params.
                createURL({ qsModule, location, routeState }) {
                  const { origin, pathname, hash, search } = location;
                  const queryParams = qsModule.parse(search.slice(1)) || {};

                  const nonAlgoliaParams = omit(queryParams, allAlgoliaIndices);

                  let queryString = qsModule.stringify(
                    {
                      ...routeState,
                      ...nonAlgoliaParams,
                    },
                    {
                      encodeValuesOnly: true,
                    }
                  );

                  if (queryString.length) {
                    queryString = `?${queryString}`;
                  }

                  return `${origin}${pathname}${queryString}${hash}`;
                },
                getLocation() {
                  if (typeof window === "undefined") {
                    return new URL(url) as unknown as Location;
                  }
                  return window.location;
                },
              }),
              stateMapping: {
                stateToRoute(uiState) {
                  if (currentTopicName) {
                    // Remove the topic state from the URL
                    delete uiState[ALGOLIA_INDICES.allDateDesc]
                      ?.refinementList?.[ALGOLIA_FACETS.insights.topic];
                  }
                  // Remove the page param because useInfiniteHits doesn't
                  // handle it correctly. https://github.com/algolia/instantsearch/issues/5263
                  Object.values(ALGOLIA_INDICES).forEach((index) => {
                    if (uiState[index]?.page) delete uiState[index].page;
                  });
                  return uiState;
                },
                routeToState(routeState) {
                  if (currentTopicName) {
                    // Add the topic back to the state
                    // Make sure the index state is defined
                    routeState[ALGOLIA_INDICES.allDateDesc] =
                      routeState[ALGOLIA_INDICES.allDateDesc] ?? {};
                    // Get the existing refinement list
                    const refinementList =
                      routeState[ALGOLIA_INDICES.allDateDesc]?.refinementList ??
                      {};
                    // add the topic to the refinement list
                    routeState[ALGOLIA_INDICES.allDateDesc].refinementList = {
                      ...refinementList,
                      [ALGOLIA_FACETS.insights.topic]: [currentTopicName],
                    };
                  }
                  return routeState;
                },
              },
            }
          : undefined
      }
    >
      {url && <ForceInstantSearchUrlSync />}
      <InsightsMiddleware />
      {children}
    </InstantSearch>
  );
}

/**
 * Sometimes the instantSearch library doesn't sync
 * properly with the url params. This fixes that.
 */
function ForceInstantSearchUrlSync() {
  const instantSearch = useInstantSearch();
  useEffectOnce(() => {
    const onChange = () => {
      const params = qs.parse(location.search.slice(1));
      const uiState = pick(params, allAlgoliaIndices);
      instantSearch.setUiState(uiState as UiState);
    };

    on(window, "popstate", onChange);
    on(window, "pushstate", onChange);
    on(window, "replacestate", onChange);

    return () => {
      off(window, "popstate", onChange);
      off(window, "pushstate", onChange);
      off(window, "replacestate", onChange);
    };
  });
  return null;
}

function InsightsMiddleware() {
  const { use } = useInstantSearch();
  const { cookies } = useCookieContext();
  const didSetMiddleware = useRef(false);

  useEffect(() => {
    if (!cookies.analytics?.accepted || didSetMiddleware.current) return;
    didSetMiddleware.current = true;
    return use(
      createInsightsMiddleware({
        insightsClient: AlgoliaInsightsClient,
        insightsInitParams: {
          useCookie: cookies.analytics?.accepted,
          userHasOptedOut: !cookies.analytics?.accepted,
        },
        onEvent({ eventType, insightsMethod, payload }, insightsClient) {
          // Just send click events. All others are just noise.
          if (insightsMethod && eventType == "click") {
            insightsClient(insightsMethod, payload);
          }
        },
      })
    );
  }, [cookies.analytics?.accepted, use]);

  return null;
}
