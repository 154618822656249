import type { SearchOptions } from "instantsearch.js/es/types";
import { useEffect, useState } from "react";
import { useSearchBox } from "react-instantsearch-hooks-web";
import { ALGOLIA_INDICES } from "~/config";
import { algoliaClient } from "./AlgoliaProvider";

/**
 *
 *
 * @param facetFilters See Algolia documentation for data format https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
 * @returns Number of hits according to provided facet filters
 *
 * https://www.algolia.com/doc/api-reference/api-parameters/facetFilters/
 * Values in arrays are "or"ed. Each array is "and"ed.
 * E.g. (Africa OR Asia) AND 2019
 *
 * Call this as many times as you like. The `algoliaclient` caches results for us.
 */
export function useNumResults(facetFilters: SearchOptions["facetFilters"]) {
  const { query } = useSearchBox();
  const [numResults, setNumResults] = useState(0);
  useEffect(() => {
    algoliaClient
      .search([
        {
          indexName: ALGOLIA_INDICES.all,
          params: {
            hitsPerPage: 0,
            facetFilters,
          },
          query,
        },
      ])
      .then((value) => {
        setNumResults(value.results[0].nbHits);
      });
  }, [facetFilters, query]);
  return numResults;
}
