import { useEffect, useState } from "react";
import type { SearchForFacetValuesResponse } from "instantsearch.js/es/types";
import { algoliaClient } from "./AlgoliaProvider";
import { ALGOLIA_INDICES } from "~/config";
/**
 *
 * @param facetName Name of attribute to search on. Must be marked as searchable in the algolia configuration.
 * @returns Any possible values for the given attribute/facet
 *
 * Call this as many times as you like. The `algoliaclient` caches results for us.
 */
export function useFacetValues(
  facetName: string,
  opts?: { disabled?: boolean }
) {
  const [facetValues, setFacetValues] = useState<
    SearchForFacetValuesResponse["facetHits"]
  >([]);
  useEffect(() => {
    if (opts?.disabled) return;
    algoliaClient
      .searchForFacetValues([
        {
          indexName: ALGOLIA_INDICES.all,
          params: {
            facetName,
            facetQuery: "",
            sortFacetValuesBy: "alpha",
            hitsPerPage: 100,
          },
        },
      ])
      .then((value) => {
        setFacetValues(value[0].facetHits ?? []);
      });
  }, [facetName, opts?.disabled]);
  return facetValues;
}
